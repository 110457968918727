import Timer from './Timer';

export default class PageRefresh {
  constructor(timeToRefresh = 60000) {
    //convet minutes to milliseconds
    console.log(timeToRefresh)
    this.timer = null;
    this.defaultWait = timeToRefresh * 60000;
    this.items = [];
  }

  hasItem(item) {
    return this.items.findIndex(i => i.name === item) > -1;
  }

  addItem(item, waitTime) {
    this.checkEnded();
    if (item) {
      if (this.hasItem(item)) {
        this.items = this.items.filter(i => i.name !== item);
      }
      const now = new Date().getTime();
      let timeout = new Date(now + waitTime);
      this.items.push({
        name: item,
        timeout
      });
      this.sortItems();
      let findIndex = this.items.findIndex(i => i.name === item);
      if (findIndex === 0) {
        this.timer.restart(this.calcTimeout(this.items[findIndex]));
      }
    }
  }

  removeItem(item) {
    this.checkEnded();
    if (item && this.hasItem(item)) {
      let findIndex = this.items.findIndex(i => i.name === item);
      this.items = this.items.filter(i => i.name !== item);
      if (findIndex === 0 && this.items.length > 0) {
        let lastItem = this.items[0];
        this.timer.restart(this.calcTimeout(lastItem));
      }
    }
    if (this.items.length === 0) {
      this.addItem('default', 5000);
    }
  }

  sortItems() {
    this.items = this.items.sort((a, b) => {
      if (a.timeout.getTime() === b.timeout.getTime()) {
        return 0;
      }
      return a.timeout.getTime() > b.timeout.getTime() ? -1 : 1;
    });
  }

  calcTimeout(item) {
    const now = new Date().getTime();
    return item.timeout.getTime() - now;
  }

  checkEnded() {
    const now = new Date().getTime();
    this.items = this.items.filter(i => i.timeout.getTime() > now);
  }

  load() {
    // Console.log(1);
    let stoprefresh;

    const t = this;

    function getParametro(nome) {
      if (!window.location.search) {
        return false;
      }
      const qst = window.location.search.substr(1);
      const dati = qst.split(/&/);

      for (let i = 0; i < dati.length; i++) {
        // eslint-disable-next-line
        const tmp = dati[i].split(/\=/);
        if (tmp[0] === nome) {
          return tmp[1];
        }
      }
      return false;
    }

    function setRefreshCookie() {
      var now = new Date();
      now.setTime(now.getTime() + 10000);
      document.cookie =
        'generatedRefresh=true; expires=' +
        now.toGMTString() +
        '; domain=.calcioefinanza.it';
    }

    function refreshSite() {
      t.checkEnded();

      if (getParametro('stoprefresh') === 'true') {
        stoprefresh = true;
      }
      if (document.body.classList.contains('stopPageRefresh')) stoprefresh = true;

      if (!stoprefresh) {
        window.history.pushState({}, null, '?refresh_ce');
        window.location.reload();
      }
    }

    setTimeout(setRefreshCookie, 295000);
    if (this.timer === null) {
      this.timer = new Timer(refreshSite, this.defaultWait);
      this.addItem('default', this.defaultWait);
    }
    /*
      Event "refreshSite" force to restart refresh timer.
      Properties required:
      waitrefreshSite: Number. Wait time in milliseconds. Set 0 to restart default wait
      fromElement: String. Unique stringId of component. 
    */
    document.addEventListener(
      'refreshSite',
      e => {
        if (
          e &&
          typeof e.waitrefreshSite !== 'undefined' &&
          typeof e.fromElement !== 'undefined'
        ) {
          const waitrefreshSite = Number(e.waitrefreshSite);
          if (waitrefreshSite === 0) {
            this.removeItem(e.fromElement);
          } else {
            this.addItem(e.fromElement, waitrefreshSite);
          }
        }
      },
      false
    );
  }
}
