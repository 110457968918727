export default class Timer {
  constructor(callback, delay) {
    this.id = null;
    this.started = null;
    this.default = delay;
    this.remaining = delay;
    this.callback = callback;
    this.running = null;
  }

  renderT(v) {
    return `0${v}`.slice(-2);
  }

  start() {
    this.running = true;
    this.started = new Date();
    this.id = setTimeout(this.callback, this.remaining);
    const now = new Date().getTime();
    const timeout = new Date(now + this.remaining);
    console.log(
      `REFRESH end at: ${this.renderT(timeout.getHours())}:${this.renderT(
        timeout.getMinutes()
      )}:${this.renderT(timeout.getSeconds())}`
    );
  }

  pause() {
    this.running = false;
    clearTimeout(this.id);
    this.remaining -= new Date() - this.started;
  }

  reset() {
    if (this.running) {
      this.pause();
      this.remaining = this.default;
      this.start();
    }
  }

  restart(waitTime) {
    if (this.running) {
      this.pause();
    }
    this.remaining = waitTime;
    this.start();
  }
}
